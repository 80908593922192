import { ACCESS_TOKEN } from "../../api/api";
import { IUSer } from "../../models/user";
import { toggleLoading } from "../../utils/herlper";
import { IInternalState, INotification, UserLoading } from "./context";
import { IProduct } from "../../models/product";

type Action =
  | { type: "LOADING"; payload: { loading: UserLoading | UserLoading[] } }
  | { type: "LOGOUT" }
  | { type: "LOGIN"; payload: { user?: IUSer } }
  | { type: "USER"; payload: { user?: IUSer } }
  | { type: "NOTIFICATIONS"; payload: { data?: INotification[] } }
  | { type: "FAVOURITES"; payload: { data?: IProduct[] } }
  | {
      type: "ADD_TO_FAVOURITES";
      payload: { product: IProduct };
    }
  | { type: "REMOVE_FROM_FAVOURITES"; payload: { id: number } }
  | { type: "ADD_TO_CART"; payload: { qunatity: number; product: IProduct } }
  | { type: "REMOVE_FROM_CART"; payload: { id: number } }
  | { type: "CLEAR_CART" };

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "LOGIN":
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: true,
      };
    case "LOGOUT":
      localStorage.removeItem(ACCESS_TOKEN);
      return {
        ...state,
        user: undefined,
        userFavourites: [],
        isAuthenticated: false,
      };

    case "ADD_TO_CART": {
      let cartItems = [...state.cart];

      let index = cartItems.findIndex(
        (item) => item.id === action.payload.product.id
      );

      if (index !== -1) {
        cartItems[index].quantity += action.payload.qunatity;
      } else {
        cartItems.push({
          ...action.payload.product,
          quantity: action.payload.qunatity,
        });
      }

      return {
        ...state,
        cart: cartItems,
      };
    }

    case "ADD_TO_FAVOURITES": {
      let favourites = [...state.userFavourites];

      let index = favourites.findIndex(
        (item) => item.id === action.payload.product.id
      );

      if (index !== -1) {
        favourites.splice(index, 1);
      } else {
        favourites.push({
          ...action.payload.product,
        });
      }

      return {
        ...state,
        userFavourites: favourites,
      };
    }

    case "REMOVE_FROM_CART": {
      let cartItems = state.cart.filter(
        (item) => item.id !== action.payload.id
      );
      return {
        ...state,
        cart: cartItems,
      };
    }

    case "CLEAR_CART": {
      return {
        ...state,
        cart: [],
      };
    }
    case "USER": {
      return {
        ...state,
        user: action.payload.user,
      };
    }
    case "NOTIFICATIONS": {
      return {
        ...state,
        notifications: action.payload.data ?? [],
      };
    }
    case "FAVOURITES": {
      return {
        ...state,
        userFavourites: action.payload.data ?? [],
      };
    }
    default:
      return state;
  }
};

export default reducer;
