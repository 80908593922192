import { Button } from "antd";
import { FaMinus, FaPlus, FaXmark } from "react-icons/fa6";
import UserContext, { ICart } from "../../../context.tsx/user/context";
import { useContext } from "react";

interface IProps {
  product: ICart;
}
const ViewCartItem = ({ product }: IProps) => {
  const { actions } = useContext(UserContext);
  return (
    <>
      {/* First Col */}
      <div className="flex gap-[20px] items-center w-auto ">
        <div className="py-[14px] px-[9px] bg-[#F9F9F9]">
          <img
            className="w-[118px] h-[118px]"
            src={product.cover[0] ?? "/images/view-cart/product-1.png"}
            alt="product"
          />
        </div>
        <div className="flex flex-col  gap-[20px] ">
          <div className="text-[24px] font-bold">{product.name}</div>
          <div className="text-[16px] text-[#7A7A7A] ">
            {product?.category?.name}
          </div>
        </div>
      </div>

      {/* Second Column */}
      <div className="flex gap-[20px] items-center ">
        <Button
          onClick={() => actions.addToCart(1, product)}
          className="rounded-none border-none shadow-lg hover:shadow-xl outline-none w-[60px] h-[60px] flex justify-center items-center"
        >
          <FaPlus />
        </Button>
        <Button className="rounded-none text-[24px] text-[#F36B10] border-none shadow-lg cursor-default outline-none w-[60px] h-[60px] flex justify-center items-center">
          {product.quantity}
        </Button>
        <Button
          onClick={() => {
            if (product.quantity - 1 > 0) {
              actions.addToCart(-1, product);
            }
          }}
          className="rounded-none border-none shadow-lg hover:shadow-xl outline-none w-[60px] h-[60px] flex justify-center items-center"
        >
          <FaMinus />
        </Button>
      </div>

      {/* Third Column */}
      <div className="flex items-center">
        <Button
          onClick={() => {
            actions.removeFromCart(product.id);
          }}
          className="rounded-none border-none shadow-lg hover:shadow-xl outline-none w-[60px] h-[60px] flex justify-center items-center"
        >
          <FaXmark />
        </Button>
      </div>

      {/* Fourth Column */}

      <div className="flex items-center">
        <div className="flex flex-col gap-[10px] ">
          <div className="text-[24px]">{product.price} $</div>

          {product.offer_type && (
            <div className="text-[16px] text-[#7A7A7A]">
              {product?.offer_type === "خصم بقيمة"
                ? `${product?.offer} $`
                : `${product.offer} %`}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewCartItem;
