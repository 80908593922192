import { Image } from "antd";

interface IProps {
  onClick: () => void;
  url: string;
  active?: boolean;
}
const BoxImage = ({ onClick, url, active }: IProps) => {
  const activeBorder = active && "border-solid border-[1px] border-[#F36B10]";

  return (
    <div
      onClick={onClick}
      className={`${activeBorder} w-[90px] cursor-pointer hover:border-solid hover:border-[1px] hover:border-[#F36B10] h-[90px] shadow-md flex justify-center items-center `}
    >
      <Image
        preview={false}
        src={url}
        className="w-[62px] h-[62px]"
        alt="afaq product image"
      />
    </div>
  );
};

export default BoxImage;
