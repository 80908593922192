import Container from "../../components/general/container/Container";
import { Tabs } from "antd";
import UserSettings from "../../components/user/settings/UserSettings";
import UserFavourites from "../../components/user/favourites/UserFavourites";
import UserOrders from "../../components/user/orders/UserOrders";
import UserNotifications from "../../components/user/notifications/UserNotifications";
import UserContactUs from "../../components/user/contact-us/UserContactUs";
import UsagePolicy from "../../components/user/usage-policy/UsagePolicy";
import { useContext } from "react";
import UserContext from "../../context.tsx/user/context";
import { Navigate, useLocation } from "react-router-dom";

const { TabPane } = Tabs;
const User = () => {
  const { isAuthenticated } = useContext(UserContext);

  const { pathname } = useLocation();

  if (!isAuthenticated) return <Navigate to={"/"} replace />;

  return (
    <Container>
      <Tabs defaultActiveKey={pathname === "/user/notifications" ? "4" : "1"}>
        <TabPane
          tab={<div className="text-[16px] font-bold">الاعدادات</div>}
          key="1"
        >
          <UserSettings />
        </TabPane>
        <TabPane
          tab={<div className="text-[16px] font-bold">المفضلة</div>}
          key="2"
        >
          <UserFavourites />
        </TabPane>
        <TabPane
          tab={<div className="text-[16px] font-bold">طلباتي</div>}
          key="3"
        >
          <UserOrders />
        </TabPane>

        <TabPane
          tab={<div className="text-[16px] font-bold">الاشعارات</div>}
          key="4"
        >
          <UserNotifications />
        </TabPane>

        <TabPane
          tab={<div className="text-[16px] font-bold">تواصل معنا</div>}
          key="5"
        >
          <UserContactUs />
        </TabPane>
        <TabPane
          tab={<div className="text-[16px] font-bold">سياسة الاستخدام</div>}
          key="6"
        >
          <UsagePolicy />
        </TabPane>
      </Tabs>
    </Container>
  );
};

export default User;
