import FormItem from "../../components/general/form-item/FormItem"
import { Button, Input } from "antd"
import { useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import UserContext from "../../context.tsx/user/context"
import { Controller, useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"

const RegisterAddress = () => {
  const navigate = useNavigate()

  const { state } = useLocation()
  const { data } = state

  const { actions, loading } = useContext(UserContext)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const onSubmit = async (addressData: any) => {
    try {
      await actions.register({ ...data, ...addressData })
    } catch (err) {}
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      dir="rtl"
      className="flex flex-col w-[100%] md:w-[80%] lg:w-[50%] h-full justify-between items-center mt-[20px]"
    >
      <div className="flex md:flex-row flex-col justify-between items-center w-full">
        <div className="flex w-1/2 flex-col gap-[16px]">
          <div className="text-[40px] font-black">العنوان</div>
          <div className="text-[18px] text-[#7A7A7A] w-[90%]">
            يجب عليك ادخال البينات الصحيح لتجنب المشاكل والاخطاء
          </div>
        </div>

        <img
          className="cursor-pointer w-[300px] object-cover md:-me-[80px] h-[200px]"
          onClick={() => navigate("/")}
          src="/images/logo.png"
          alt=""
        />
      </div>

      <div className="flex w-full flex-col gap-[40px] mt-[50px]">
        <FormItem label="عنوان المنزل">
          <Controller
            control={control}
            name="address"
            rules={{
              required: { value: true, message: "عنوان المنزل مطلوب" },
            }}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  placeholder="عنوان المنزل"
                  className="h-[70px] rounded-none text-[15px] font-black"
                />
              )
            }}
          />
          <ErrorMessage
            className="mt-4 text-red-600"
            as={"div"}
            errors={errors}
            name="address"
          />
        </FormItem>

        <FormItem label="المحافظة">
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "المحافظة مطلوب" },
            }}
            name="country"
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  placeholder="المحافظة"
                  className="h-[70px] rounded-none text-[15px] font-black"
                />
              )
            }}
          />
          <ErrorMessage
            className="mt-4 text-red-600"
            as={"div"}
            errors={errors}
            name="country"
          />
        </FormItem>

        <FormItem label="المنطقة">
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "المنطقة مطلوب" },
            }}
            name="city"
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  placeholder="المنطقة"
                  className="h-[70px] rounded-none text-[15px] font-black"
                />
              )
            }}
          />
          <ErrorMessage
            className="mt-4 text-red-600"
            as={"div"}
            errors={errors}
            name="city"
          />
        </FormItem>
      </div>

      <div className="w-full mt-[40px] flex justify-between gap-[40px]">
        <Button
          className="w-1/2 h-[74px] text-[20px] font-black"
          type="primary"
          htmlType="submit"
          loading={loading.includes("register")}

          // onClick={() => navigate("address")}
        >
          متابعة
        </Button>
        <Button
          className="w-1/2 text-[#F36B10] text-[20px] font-black h-[74px]"
          type="default"
          onClick={() => navigate("/login")}
        >
          تسجيل الدخول
        </Button>
      </div>
    </form>
  )
}

export default RegisterAddress
