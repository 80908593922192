import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

interface IProps {
  images: string[];
}
const ImageCover = ({ images }: IProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 === images.length ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    if (images.length !== 0) {
      const subscribtion = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex + 1 === images.length ? 0 : prevIndex + 1
        );
      }, 4000);
      return () => clearInterval(subscribtion);
    }
  }, [images]);

  return (
    <div className="relative">
      {images.length === 0 ? (
        <img width={"100%"} src="/images/cover.png" alt="afaq cover" />
      ) : (
        <>
          {images.map((item, index) => {
            return (
              <React.Fragment key={item}>
                <div
                  className={`${
                    currentIndex === index
                      ? "opacity-100 block"
                      : "opacity-0 hidden"
                  } 
                
                 transition-opacity duration-1000`}
                >
                  <img
                    src={item}
                    alt=""
                    className={`w-full h-[700px] object-fill object-center`}
                  />
                </div>
              </React.Fragment>
            );
          })}
        </>
      )}
      {images.length !== 0 && (
        <div className="absolute bottom-0 pr-[30px] md:pr-[140px] flex flex-col gap-[20px] items-center justify-center">
          <div className="flex gap-[20px] bottom-[70px] md:bottom-[120px] relative right-[50px]">
            <motion.button
              className="bg-transparent outline-none border-none cursor-pointer"
              whileTap={{ scale: 0.8 }}
            >
              <BsArrowRight
                onClick={handleNext}
                className="w-[33px] h-[33px]"
              />
            </motion.button>
            <div className="flex justify-center items-center relative bottom-1">
              <div className="text-[16px] font-bold text-[#9F9F9F]">
                {images.length.toString().padStart(2, "0")} /
              </div>
              <div className="text-[#F36B10] font-bold text-[30px] relative bottom-1 right-1">
                {(currentIndex + 1).toString().padStart(2, "0")}
              </div>
            </div>
            <motion.button
              className="bg-transparent outline-none border-none cursor-pointer"
              whileTap={{ scale: 0.8 }}
            >
              <BsArrowLeft
                onClick={() => {
                  handlePrevious();
                }}
                className="w-[33px] h-[33px]"
              />
            </motion.button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageCover;
