import { useLocation, useNavigate } from "react-router-dom"
import { StatefulPinInput } from "react-input-pin-code"
import { useContext, useState } from "react"
import { Button } from "antd"
import http from "../../api/api"
import UserContext from "../../context.tsx/user/context"

const CheckEmail = () => {
  const navigate = useNavigate()
  const [text, setText] = useState<string[]>([])

  const [check, setCheck] = useState(true)

  const { state } = useLocation()
  const { email } = state ?? ""
  const [loading, setLoading] = useState(false)

  const { actions } = useContext(UserContext)

  const checkEmail = async () => {
    try {
      setLoading(true)
      const { data } = await http.post("/auth/verify", {
        email,
        code: text.join(""),
      })
      actions.setUser({ user: data.data.user, token: data.data.token })
      navigate("/")
    } catch (err) {
    } finally {
      setLoading(false)
    }
  }

  const resendVerficationCode = async () => {
    try {
      setLoading(true)
      await http.post("/auth/resend-verification-code", {
        email,
      })
    } catch (err) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      // onSubmit={handleSubmit(onSubmit)}
      dir="rtl"
      className="flex flex-col w-[100%] md:w-[80%] lg:w-[50%] h-full justify-between items-center mt-[20px]"
    >
      <div className="flex flex-col md:flex-row justify-between items-center w-full">
        <div className="flex w-1/2 flex-col gap-[16px]">
          <div className="text-[40px] font-black">
            تحقق من البريد الالكتروني
          </div>
          <div className="text-[18px] text-[#7A7A7A] w-[90%]">
            احصل على رمز الحماية برسالة بريد قصيرة إلي AFAQ...@GMAIL.COM{" "}
          </div>
        </div>
        <img
          className="cursor-pointer w-[300px] object-cover md:-me-[80px] h-[200px]"
          onClick={() => navigate("/")}
          src="/images/logo.png"
          alt=""
        />
      </div>

      <div
        className="flex w-full items-center flex-col gap-[40px] mt-[50px]"
        dir="ltr"
      >
        <StatefulPinInput
          length={6}
          initialValue=""
          onChange={(values, index) => {
            // setText(value);
            if (values.length < 6) setCheck(true)
          }}
          showState={false}
          onComplete={(values) => {
            setCheck(false)
            setText(values)
          }}
          focusBorderColor="#f36b10"
          inputStyle={{
            background: "white",
            border: "none",
            margin: "0 16px",
            height: "100px",
            fontSize: "40px",
            width: "100px",
          }}
          placeholder=""
          mask={false}
        />
      </div>

      <div className="w-full mt-[40px] mb-[27.9px] flex justify-between gap-[40px]">
        <Button
          className="w-1/2 h-[74px] text-[20px] font-black"
          type="primary"
          disabled={check}
          loading={loading}
          //   loading={loading.includes("register")}
          onClick={checkEmail}
        >
          تحقق
        </Button>
        <Button
          className="w-1/2 text-[#F36B10] text-[20px] font-black h-[74px]"
          type="default"
          loading={loading}
          onClick={resendVerficationCode}
        >
          ارسال الرمز
        </Button>
      </div>
    </div>
  )
}

export default CheckEmail
