import { ErrorMessage } from "@hookform/error-message"
import { Button, Checkbox, Input } from "antd"
import { useContext } from "react"
import { Controller, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import UserContext from "../../context.tsx/user/context"

const Login = () => {
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors },
  } = useForm()

  const { actions, loading } = useContext(UserContext)

  const onSubmit = async (data: any) => {
    await actions.login({ email: data.email, password: data.password })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      dir="rtl"
      className="flex flex-col w-[100%] md:w-[80%] lg:w-[50%] h-full justify-between items-center mt-[100px]"
    >
      <div className="flex w-full flex-col md:flex-row  justify-between items-center">
        <div className="flex flex-col gap-[30px]">
          <div className="text-[40px] font-black">
            مرحباً بك في <span className="text-[#F36B10]">آفاق</span>
          </div>
          <div className="flex  gap-[15px] items-center">
            <div className="h-[5px] w-[120px] rounded-md bg-[#F36B10]"></div>

            <div className="h-[5px] w-[120px] rounded-md bg-[#A8A8A8]"></div>
          </div>
        </div>
        <img
          onClick={() => navigate("/")}
          className="cursor-pointer w-[300px] object-cover md:-me-[80px] h-[200px]"
          src="/images/logo.png"
          alt=""
        />
      </div>

      <div className="w-full mt-[100px]">
        <Controller
          control={control}
          rules={{
            required: { value: true, message: "البريد الالكتروني مطلوب" },
          }}
          name="email"
          render={({ field }) => {
            return (
              <Input
                {...field}
                type="email"
                className="h-[60px] rounded-none text-[16px] font-black"
                placeholder="البريد الالكتروني"
                prefix={<img src="/images/login/env.png" alt="" />}
              />
            )
          }}
        />

        <Controller
          control={control}
          rules={{
            required: { value: true, message: "كلمة المرور مطلوبة" },
          }}
          name="password"
          render={({ field }) => {
            return (
              <Input.Password
                {...field}
                className="h-[60px] rounded-none text-[16px] font-black"
                placeholder="كلمة المرور"
                prefix={<img src="/images/login/key.png" alt="" />}
              />
            )
          }}
        />
      </div>

      <div className="flex w-full items-center mt-[34px] justify-between">
        <Controller
          rules={{
            required: {
              value: true,
              message: "يرجى الموافقة على سياسة الخصوصية",
            },
          }}
          control={control}
          name="check"
          render={({ field }) => {
            return (
              <Checkbox
                {...field}
                checked={field.value}
                className="text-[15px] text-[#7A7A7A]"
              >
                موافق علي سياسة الخصوصية
              </Checkbox>
            )
          }}
        />

        <div
          className="text-[#7A7A7A] cursor-pointer no-underline hover:underline hover:text-[#F36B10] transition ease-in-out text-[15px] "
          color="black"
          onClick={() => {
            trigger("email").then((val) => {
              if (val) {
                navigate(`forget-password`, {
                  state: { email: getValues("email") },
                })
              }
            })
          }}
        >
          هل نسيت كلمة المرور ؟
        </div>
      </div>

      {/* Error Messsages */}
      <span className="mt-4 text-red-600 flex flex-col items-center">
        <ErrorMessage as={"div"} errors={errors} name="email" />
        {/* <span className="mt-4 text-red-600 block"> */}
        <ErrorMessage as={"div"} errors={errors} name="password" />
        {/* </span> */}
        {/* <span className="mt-4 text-red-600 block"> */}
        <ErrorMessage as={"div"} errors={errors} name="check" />
        {/* </span> */}
      </span>

      <div className="flex gap-[40px] mb-[43px] mt-[43px] w-full justify-between">
        <Button
          className="w-1/2 h-[74px] text-[20px] font-black"
          type="primary"
          htmlType="submit"
          loading={loading.includes("login")}
          // onClick={() => navigate("/user")}
        >
          تسجيل الدخول
        </Button>
        <Button
          className="w-1/2 text-[#F36B10] text-[20px] font-black h-[74px]"
          type="default"
          htmlType="button"
          onClick={() => navigate("register")}
        >
          انشاء حساب
        </Button>
      </div>
    </form>
  )
}

export default Login
