import { ReactNode, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import reducer from "./reducer";
import UserContext, { internatState } from "./context";
import http, { ACCESS_TOKEN } from "../../api/api";
import { ILogin, ISignUp } from "../../models/user";
import { notification } from "antd";
import { IProduct } from "../../models/product";

interface IProps {
  children: ReactNode;
}

const UserContextProvider = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(reducer, internatState);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      state.isAuthenticated &&
      !state.user &&
      localStorage.getItem(ACCESS_TOKEN) !== undefined
    ) {
      me();
      notifications();
      getUserFavourites();
    }
  }, [state.isAuthenticated, state.user]);

  const me = async () => {
    try {
      const { data } = await http.get("/auth/me");
      dispatch({ type: "USER", payload: { user: data.data } });
    } catch (err) {
      dispatch({ type: "LOGOUT" });
    } finally {
    }
  };

  const notifications = async () => {
    try {
      const { data } = await http.get("/notifications");

      dispatch({ type: "NOTIFICATIONS", payload: { data: data.data } });
    } catch (err) {
      // dispatch({ type: "LOGOUT" });
    } finally {
    }
  };

  const login = async (req: ILogin) => {
    try {
      dispatch({ type: "LOADING", payload: { loading: "login" } });
      const { data } = await http.post("/auth/login", req);
      localStorage.setItem(ACCESS_TOKEN, data.data.token);
      dispatch({ type: "LOGIN", payload: { user: data.data.user } });
      notifications();
      navigate("/");
    } catch (err) {
    } finally {
      dispatch({ type: "LOADING", payload: { loading: "login" } });
    }
  };

  const register = async (req: ISignUp) => {
    try {
      dispatch({ type: "LOADING", payload: { loading: "register" } });
      await http.post("/auth/register", req);
      navigate("/login/register/check-email", {
        state: { email: req.email },
      });
    } catch (err: any) {
      let text = "";
      Object.keys(err.response.data.data).forEach((item) => {
        text = err.response.data.data[`${item}`];
      });
      notification.error({
        description: `${text[0]}`,
        message: `${err.response.data.message}`,
        placement: "bottomRight",
        style: { direction: "rtl" },
      });
    } finally {
      dispatch({ type: "LOADING", payload: { loading: "register" } });
    }
  };

  const forgetPassword = async (email: string) => {
    try {
      dispatch({ type: "LOADING", payload: { loading: "forget_password" } });
      await http.post("/auth/forget-password", { email });
      notification.success({
        message: "تمت ارسال كود بنجاح",
        placement: "bottomRight",
        style: { direction: "rtl" },
      });
      navigate("/login/reset-password", { state: { email } });
    } catch (err) {
      notification.error({
        message: "حدث خطأ ما يرجى المحاولة لاحقاً",
        placement: "bottomRight",
        style: { direction: "rtl" },
      });
    } finally {
      dispatch({ type: "LOADING", payload: { loading: "forget_password" } });
    }
  };

  const setUser = (data: any) => {
    localStorage.setItem(ACCESS_TOKEN, data.token);
    dispatch({ type: "LOGIN", payload: { user: data.user } });
  };

  const logout = async () => {
    try {
      dispatch({ type: "LOADING", payload: { loading: "logout" } });
      await http.post("/auth/logout");
      notification.success({
        message: "تم تسجيل الخروج بنجاح",
        placement: "bottomRight",
        style: { direction: "rtl" },
      });
      dispatch({ type: "CLEAR_CART" });

      dispatch({ type: "LOGOUT" });
    } catch (err) {
      notification.error({
        message: "حدث خطأ ما يرجى المحاولة لاحقاً",
        placement: "bottomRight",
        style: { direction: "rtl" },
      });
    } finally {
      dispatch({ type: "LOADING", payload: { loading: "logout" } });
    }
  };

  // -------------- Cart Functionality ---------

  const addToCart = (qunatity: number, product: IProduct) => {
    dispatch({
      type: "ADD_TO_CART",
      payload: {
        product,
        qunatity,
      },
    });
  };

  const removeFromCart = (id: number) => {
    dispatch({
      type: "REMOVE_FROM_CART",
      payload: {
        id,
      },
    });
  };

  const updateUserFavourites = async (product: IProduct) => {
    try {
      dispatch({
        type: "ADD_TO_FAVOURITES",
        payload: {
          product,
        },
      });
      const isFound = state.userFavourites.some((element) => {
        if (element.id === product.id) {
          return true;
        }

        return false;
      });

      if (isFound) {
        http.delete(`/favorites/${product.id}`);
      } else {
        http.post("/favorites", {
          product_id: product.id,
        });
      }
    } catch (err) {
    } finally {
    }
  };

  const createOrder = async (data: any, note?: string, code?: string) => {
    try {
      dispatch({ type: "LOADING", payload: { loading: "create_order" } });

      let submittedData: any = {};

      if (note) submittedData["note"] = note;
      if (code) submittedData["coupon_code"] = code;

      submittedData["item_count"] = data.length;

      for (const [index, item] of data.entries()) {
        submittedData[`product_id_${index + 1}`] = item.id;
        submittedData[`quantity_${index + 1}`] = item.quantity;
      }

      await http.post("/orders", submittedData);

      dispatch({ type: "CLEAR_CART" });

      navigate("/successful-purchase");
      // navigate("/");
    } catch (err) {
    } finally {
      dispatch({ type: "LOADING", payload: { loading: "create_order" } });
    }
  };

  const getUserFavourites = async () => {
    try {
      dispatch({
        type: "LOADING",
        payload: { loading: "get_user_favourites" },
      });

      const { data } = await http.get("/favorites");
      dispatch({ type: "FAVOURITES", payload: { data: data.data } });
    } catch (err) {
    } finally {
      dispatch({
        type: "LOADING",
        payload: { loading: "get_user_favourites" },
      });
    }
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        actions: {
          login,
          register,
          forgetPassword,
          updateUserFavourites,
          logout,
          setUser,
          addToCart,
          me,
          removeFromCart,
          createOrder,
          getUserFavourites,
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
