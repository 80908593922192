import { IHomeData } from "../../models/home";
import { IInternalState } from "./context";

type Action =
  | {
      type: "LOADING";
      payload: { loading: boolean };
    }
  | {
      type: "SET_DATA";
      payload: { data: IHomeData };
    };

const reducer = (state: IInternalState, action: Action) => {
  switch (action.type) {
    case "LOADING": {
      return { ...state, loading: action.payload.loading };
    }
    case "SET_DATA": {
      return { ...state, data: action.payload.data };
    }
  }
};

export default reducer;
