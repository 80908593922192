import { Fragment } from "react";
import BoxImage from "./box-image/BoxImage";

interface IProps {
  images: string[];
  onClick: (index: number) => void;
  currentIndex: number;
}
const BoxImages = ({ onClick, images, currentIndex }: IProps) => {
  return (
    <div className="flex gap-[20px] items-center">
      {images.map((item, index) => {
        return (
          <Fragment key={index}>
            <BoxImage
              url={item}
              active={index === currentIndex ? true : false}
              onClick={() => {
                onClick(index);
              }}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default BoxImages;
