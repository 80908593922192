import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom";
import UserContextProvider from "./context.tsx/user/provider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ConfigProvider
      direction="rtl"
      theme={{
        components: {
          Layout: {
            headerBg: "white",
            algorithm: true,
            bodyBg: "white",
            footerBg: "#000000",
          },
        },
        token: {
          colorPrimary: "#F36B10",
        },
      }}
    >
      <UserContextProvider>
        <App />
      </UserContextProvider>
    </ConfigProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
