import { ReactNode } from "react";

interface Props {
  label: string;
  forName?: string;
  children: ReactNode;
}
const FormItem = ({ label, children, forName }: Props) => {
  return (
    <div className="flex flex-col gap-[20px] justify-center">
      {/* Label */}
      <label className="text-[16px] text-[#D1D1D1]" htmlFor={forName}>
        {label}
      </label>
      <div>{children}</div>
    </div>
  );
};

export default FormItem;
