import React from "react";

const UserContactUs = () => {
  return (
    <div className="mt-4 flex flex-col gap-[40px]">
      <div className="flex items-center gap-[40px]">
        <img src="/images/contact-us/1.png" alt="" />
        <div className="text-[20px] font-bold">https://www.whatsapp.com</div>
      </div>
      <div className="flex items-center gap-[40px]">
        <img src="/images/contact-us/2.png" alt="" />
        <div className="text-[20px] font-bold">https://www.facebook.com</div>
      </div>
      <div className="flex items-center gap-[40px]">
        <img src="/images/contact-us/3.png" alt="" />
        <div className="text-[20px] font-bold">076213598543</div>
      </div>
    </div>
  );
};

export default UserContactUs;
