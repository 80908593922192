import Container from "../../components/general/container/Container";
import { Button, Divider } from "antd";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import UserContext from "../../context.tsx/user/context";
import ViewCartItem from "./view-cart-item/ViewCartItem";
import http from "../../api/api";

interface ICoupon {
  totalPrice: number;
  offer: string;
}
const ViewCart = () => {
  // const navigate = useNavigate();

  const [coupon, setCoupon] = useState<ICoupon>();
  const [couponError, setCouponError] = useState("");
  const couponRef = useRef<HTMLInputElement>(null);

  const { cart, actions, loading } = useContext(UserContext);

  const noteRef = useRef<HTMLInputElement>(null);

  const [totalPrice, setTotalPrice] = useState();
  const [deliveryPrice, setDeliverPrice] = useState(0);

  useEffect(() => {
    const getTotalPrice = (deliveryPrice: number) => {
      let totalPrice = cart.reduce(
        (acc, item) => item.quantity * item.price + acc,
        deliveryPrice
      );

      return totalPrice - getDiscount();
    };
    const getDiscount = () => {
      let discount = 0;
      for (const item of cart) {
        if (item.offer_type) {
          if (item.offer_type === "خصم بقيمة") {
            discount += item.offer;
          } else {
            discount = item.price * (item.offer / 100) + discount;
          }
        }
      }

      return discount;
    };
    const getData = async () => {
      try {
        const { data } = await http.post("/setting", { key: "deliveryPrice" });
        setDeliverPrice(data.data.value);
        setTotalPrice(getTotalPrice(parseInt(data.data.value)) as any);
      } catch (err) {}
    };
    getData();
  }, [cart]);

  const getDiscount = () => {
    let discount = 0;
    for (const item of cart) {
      if (item.offer_type) {
        if (item.offer_type === "خصم بقيمة") {
          discount += item.offer;
        } else {
          discount = item.price * (item.offer / 100) + discount;
        }
      }
    }

    return discount;
  };

  const [couponLoading, setCouponLoading] = useState(false);
  const checkCoupon = async (couponIn: string) => {
    try {
      setCouponLoading(true);
      const { data } = await http.post("/coupon", {
        code: couponIn,
        total_price: totalPrice,
      });
      setTotalPrice(data.data.total_price);
      setCoupon(data.data);
      setCouponError("");
    } catch (err) {
      setCouponError("هذا الكود غير صالح برجاء التأكد من صلاحية الكود");
    } finally {
      setCouponLoading(false);
    }
  };

  return (
    <Container>
      <div className="flex flex-col gap-[10px] items-center md:grid md:grid-cols-[auto,auto, auto,auto] grow-1 md:auto-cols-auto md:grid-md:flow-row-dense">
        <div className="hidden md:inline-grid text-[24px] font-bold">
          المنتج
        </div>
        <div className="hidden md:inline-grid text-[24px] font-bold">العدد</div>
        <div className="hidden md:inline-grid text-[24px] font-bold">
          الغاء منتج
        </div>
        <div className="hidden md:inline-grid text-[24px] font-bold">السعر</div>
        <div className="col-span-4 mb-[100px]">
          <Divider className="bg-[#C0C0C0] hidden md:inline-grid " />
        </div>

        {cart.length === 0 && (
          <div className="col-span-4 -mt-16 font-bold flex justify-center items-center">
            لايوجد منتجات في السلة
          </div>
        )}

        {cart.map((item, index) => {
          return (
            <Fragment key={index}>
              <ViewCartItem product={item} />

              {cart.length - 1 !== index && (
                <div className="col-span-4 mt-[30px] mb-[30px]">
                  <Divider className="bg-[#C0C0C0] " />
                </div>
              )}
            </Fragment>
          );
        })}
      </div>

      {cart.length !== 0 && (
        <>
          {/* Pricing */}
          <div className="flex flex-col md:grid md:grid-cols-4 grow mt-[50px] gap-[20px]">
            {getDiscount() > 0 && (
              <div className="border-solid flex gap-[20px] justify-between items-center border-[1px] border-[#707070] px-[32px] py-[35px]">
                <div className="text-[#545353] text-[20px]">الخصم</div>
                <div className="text-[16px]  font-bold">{getDiscount()}</div>
              </div>
            )}
            {coupon && (
              <div className="border-solid flex gap-[20px] justify-between items-center border-[1px] border-[#707070] px-[32px] py-[35px]">
                <div className="text-[#545353] text-[20px]">خصم الكوبون</div>
                <div className="text-[16px]  font-bold">
                  {coupon?.offer ?? "-"}
                </div>
              </div>
            )}

            <div className="border-solid flex gap-[20px] justify-between items-center border-[1px] border-[#707070] px-[32px] py-[35px]">
              <div className="text-[#545353] text-[20px]">التوصيل</div>
              <div className="text-[16px]  font-bold">{deliveryPrice}</div>
            </div>
            <div className="border-solid flex gap-[20px] justify-between items-center border-[1px] border-[#707070] px-[32px] py-[35px]">
              <div className="text-[#545353] text-[20px]">الاجمالي</div>
              <div className="text-[16px]  font-bold">{totalPrice}</div>
            </div>
          </div>

          {/* Coupon */}
          <div className="flex flex-col gap-[10px] md:gap-0 md:flex-row mt-[50px] items-center justify-between">
            <div className="flex border-solid border-[1px] border-[#CCCCCC] justify-between items-center w-full">
              <input
                placeholder="أدخل الكود"
                ref={couponRef}
                className="rounded-none p-[20px] w-full hover:border-none focus:border-none focus:outline-none hover:outline-none hover:rounded-none border-none h-[50px]"
              />
              <div className="text-[16px] text-[#FF1919] w-[60%] ">
                {couponError}
              </div>
            </div>
            <Button
              onClick={() => {
                if (couponRef.current) {
                  checkCoupon(couponRef?.current?.value);
                }
              }}
              loading={loading.includes("create_order") || couponLoading}
              className="rounded-none border-none shadow-lg text-[24px] text-[#F36B10] flex justify-center items-centers font-bold  h-[50px] px-[82px]"
            >
              تطبيق الكود
            </Button>
          </div>

          <div className="flex flex-col gap-[10px] md:gap-0 md:flex-row mt-[50px] items-center justify-between">
            <div className="flex border-solid border-[1px] border-[#CCCCCC] justify-between items-center w-full">
              <input
                ref={noteRef}
                placeholder="أدخل ملاحظاتك"
                className="rounded-none p-[20px] w-full hover:border-none focus:border-none focus:outline-none hover:outline-none hover:rounded-none border-none h-[50px]"
              />
            </div>

            <Button
              onClick={() => {
                actions.createOrder(
                  cart,
                  noteRef?.current?.value,
                  couponRef?.current?.value
                );
              }}
              loading={loading.includes("create_order") || couponLoading}
              type="primary"
              className="text-[24px] rounded-none  flex justify-center items-centers font-bold  h-[50px] px-[82px]"
            >
              متابعة الشراء
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};

export default ViewCart;
