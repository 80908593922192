import { ErrorMessage } from "@hookform/error-message"
import FormItem from "../../components/general/form-item/FormItem"
import { Button, Input, notification } from "antd"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { StatefulPinInput } from "react-input-pin-code"
import { useLocation, useNavigate } from "react-router-dom"
import http from "../../api/api"

const ResetPassword = () => {
  const navigate = useNavigate()

  const { state } = useLocation()
  const { email } = state ?? ""

  // form actions
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm()
  const [loading, setLoading] = useState(false)
  const onSubmit = async (data: any) => {
    try {
      setLoading(true)
      await http.post("/auth/reset-password", {
        token: data.code,
        password: data.password,
        email,
      })
      navigate("/login")
    } catch (err: any) {
      if (err.response.data.message) {
        notification.error({
          message: err.response.data.message,
          placement: "bottomRight",
          style: { direction: "rtl" },
        })
      } else {
        notification.error({
          message: "حدث خطأ ما يرجى المحاولة لاحقاً",
          placement: "bottomRight",
          style: { direction: "rtl" },
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      dir="rtl"
      className="flex flex-col w-[100%] md:w-[80%] lg:w-[50%] h-full justify-between items-center mt-[20px]"
    >
      <div className="flex md:flex-row flex-col justify-between items-center w-full">
        <div className="flex w-1/2 flex-col gap-[16px]">
          <div className="text-[40px] font-black">إعادة ضبط كلمة المرور</div>
          <div className="text-[18px] text-[#7A7A7A] w-[90%]">
            اختر كلمة مرور يسهل عليك تذكرها
          </div>
        </div>

        <img
          className="cursor-pointer w-[300px] object-cover md:-me-[80px] h-[200px]"
          onClick={() => navigate("/")}
          src="/images/logo.png"
          alt=""
        />
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col gap-[40px] mt-[50px]"
      >
        {/* code */}
        <div
          className="flex flex-col w-full items-center justify-center"
          dir="ltr"
        >
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "الكود مطلوب" },
            }}
            name="code"
            render={({ field }) => {
              return (
                <StatefulPinInput
                  length={6}
                  initialValue=""
                  onChange={(values, index) => {
                    // setText(value);
                  }}
                  showState={false}
                  onComplete={(values) => {
                    field.onChange(values.join(""))
                  }}
                  focusBorderColor="#f36b10"
                  inputStyle={{
                    background: "white",
                    border: "none",
                    margin: "0 16px",
                    height: "100px",
                    fontSize: "40px",
                    width: "100px",
                  }}
                  placeholder=""
                  mask={false}
                />
              )
            }}
          />
          <ErrorMessage
            className="mt-4 text-red-600"
            as={"div"}
            errors={errors}
            name="code"
          />
        </div>
        {/* password */}
        <FormItem label="كلمة المرور">
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "كلمة المرور مطلوبة" },
            }}
            name="password"
            render={({ field }) => {
              return (
                <Input.Password
                  {...field}
                  className="h-[70px] rounded-none text-[15px] font-black"
                  placeholder="كلمة المرور"
                />
              )
            }}
          />
          <ErrorMessage
            className="mt-4 text-red-600"
            as={"div"}
            errors={errors}
            name="password"
          />
        </FormItem>
        <Controller
          control={control}
          rules={{
            required: { value: true, message: "تأكيد كلمة المرور مطلوبة" },
            validate: (value) => {
              return (
                value === getValues("password") || `كلمتا المرور غير متطابقتان`
              )
            },
          }}
          name="confirmPassword"
          render={({ field }) => {
            return (
              <Input.Password
                {...field}
                className="h-[70px] rounded-none text-[15px] font-black"
                placeholder="كلمة المرور"
              />
            )
          }}
        />
        <ErrorMessage
          className="mt-4 text-red-600"
          as={"div"}
          errors={errors}
          name="confirmPassword"
        />

        <Button
          type="primary"
          loading={loading}
          htmlType="submit"
          className="h-[70px] text-[20px] font-black"
        >
          متابعة
        </Button>
      </form>
    </div>
  )
}

export default ResetPassword
