import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}
const Container = ({ children }: Props) => {
  return (
    <div className="pr-[40px] pl-[40px] md:pr-[80px] md:pl-[80px] lg:pr-[100px] lg:pl-[100px] xl:pr-[140px] xl:pl-[140px] mt-[50px] ">
      {children}
    </div>
  );
};

export default Container;
