import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      className="flex flex-col justify-center h-full mt-[40px] items-center"
      title="404"
      rootClassName="bg"
      style={{ color: "red" }}
      subTitle="عذراً هذه الصفحة غير موجودة"
      extra={
        <Button
          className="text-[30px] font-black h-[70px]"
          onClick={() => navigate("/")}
          type="primary"
        >
          العودة للصفحة الرئيسية
        </Button>
      }
    />
  );
};

export default NotFound;
