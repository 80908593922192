import { Button } from "antd"
import { useContext } from "react"
import { FaCircleQuestion } from "react-icons/fa6"
import { useLocation, useNavigate } from "react-router-dom"
import UserContext from "../../context.tsx/user/context"

const ForgetPassword = () => {
  const navigate = useNavigate()

  const { state } = useLocation()
  const { email } = state

  const { actions, loading } = useContext(UserContext)

  return (
    <div
      dir="rtl"
      className="flex flex-col w-[100%] md:w-[80%] lg:w-[50%] h-full justify-between items-center mt-[20px]"
    >
      <div className="flex flex-col md:flex-row justify-between items-center w-full">
        <div className="flex w-1/2 flex-col gap-[16px]">
          <div className="text-[40px] font-black">هل نسيت كلمة السر؟</div>
          <div className="text-[18px] text-[#7A7A7A] w-[90%]">
            لا تقلق! دعنا نساعدك لاستعادة كلمه المرور الخاصة بك
          </div>
        </div>

        <img
          className="cursor-pointer w-[300px] object-cover md:-me-[80px] h-[200px]"
          onClick={() => navigate("/")}
          src="/images/logo.png"
          alt=""
        />
      </div>

      <div className="flex mt-[40px] rounded-md bg-white flex-col w-full gap-[30px] px-[51px] py-[28px]">
        <div className="flex w-full text-[29px] justify-between items-center">
          <div>أرسل إلى البريد الالكتروني</div>
          <div>
            <img
              className="w-[35px] h-[35px]"
              src="/images/envelope.png"
              alt=""
            />
          </div>
        </div>
        <div className="w-[65%] text-[22px]">
          احصل على رمز الحماية برسالة بريد قصيرة إلي AFAQ...@GMAIL.COM
        </div>
      </div>

      <div className="flex flex-col mt-[50px] gap-[30px] items-center">
        <div className="flex items-center h-auto text-[29px] gap-[10px]">
          تحتاج الي المساعدة
          <FaCircleQuestion
            height={20}
            width={20}
            style={{ width: "25px", height: "25px" }}
            className="w-[30px] h-[30px]"
          />
        </div>
        <div className="text-[25px]">
          يرجى إرسال تعليقات أو تقارير الأخطاء إلى
        </div>
        <div className="text-[29px]">Company@Gmail.Com</div>
      </div>

      <div className="w-full mt-[40px] flex justify-between gap-[40px]">
        <Button
          className="w-1/2 h-[74px] text-[20px] font-black"
          type="primary"
          loading={loading.includes("forget_password")}
          onClick={async () => {
            await actions.forgetPassword(email)
          }}
        >
          ارسال الرمز
        </Button>
        <Button
          className="w-1/2 text-[#F36B10] text-[20px] font-black h-[74px]"
          type="default"
          onClick={() => navigate("/login")}
        >
          تسجيل الدخول
        </Button>
      </div>
    </div>
  )
}

export default ForgetPassword
