import React from "react";

interface IProps {
  orderItem: any;
  status?: string;
}
const OrderItem = ({ orderItem, status }: IProps) => {
  return (
    <>
      <div className="flex gap-[20px]">
        <div>
          <img
            className="bg-white h-[100px] object-contain w-[100px]"
            src={orderItem.product.cover[0]}
            alt=""
          />
        </div>
        <div className="flex flex-col justify-center gap-[20px]">
          <div className="text-[20px] font-bold ">
            {orderItem?.product?.name}
          </div>
          <div className="text-[16px]">{orderItem.product.category.name}</div>
        </div>
      </div>
      {/* Second Column */}
      <div className="text-[24px] text-center">عدد {orderItem?.quantity}</div>
      {/* Third Column */}
      <div className="text-[24px]">{orderItem.amount} $</div>
      {/* Fourth Column */}
      <div className="text-[24px]">{status}</div>
    </>
  );
};

export default OrderItem;
