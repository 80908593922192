import { Fragment, useContext } from "react";
import UserContext from "../../../context.tsx/user/context";
import ProductCard from "../../general/product-card/ProductCard";

const UserFavourites = () => {
  const { userFavourites } = useContext(UserContext);
  return (
    <div className="grid grid-cols-4 gap-[94px] mt-4">
      {userFavourites.map((item) => {
        return (
          <Fragment key={item.id}>
            <ProductCard product={item} />
          </Fragment>
        );
      })}
    </div>
  );
};

export default UserFavourites;
