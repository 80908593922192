import "./App.css";
import MainLayout from "./components/main-layout/MainLayout";
import { Outlet, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import MostSelling from "./pages/most-selling/MostSelling";
import LatestOffers from "./pages/latest-offers/LatestOffers";
import Category from "./pages/category/Category";
import CategorySub from "./pages/category-sub/CategorySub";
import ProductDetails from "./pages/product-details/ProductDetails";
import ViewCart from "./pages/view-cart/ViewCart";
import ConfirmAddress from "./pages/confirm-address/ConfirmAddress";
import SuccessfulPurchase from "./pages/successful-purchase/SuccessfulPurchase";
import NotFound from "./pages/404/NotFound";
import RegistrationLayout from "./components/regitration-layout/RegistrationLayout";
import Login from "./pages/login/Login";
import ForgetPassword from "./pages/forget-password/ForgetPassword";
import ResetPassword from "./pages/reset-password/ResetPassword";
import Register from "./pages/register/Register";
import RegisterAddress from "./pages/register-address/RegisterAddress";
import User from "./pages/user/User";
import HomeContextProvider from "./context.tsx/home/provider";
import CheckEmail from "./pages/check-email/CheckEmail";
import SearchedProducts from "./pages/searched-products/SearchedProducts";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route
            index
            element={
              <HomeContextProvider>
                <Home />
              </HomeContextProvider>
            }
          />
          <Route path="/most-selling" element={<MostSelling />} />
          <Route path="/latest-offers" element={<LatestOffers />} />
          <Route path="/category-sub/:sub" element={<CategorySub />} />
          <Route path="/category/:category" element={<Category />} />
          <Route path="/product/:product" element={<ProductDetails />} />
          <Route
            path="/search-products/:search"
            element={<SearchedProducts />}
          />
          <Route path="/view-cart" element={<ViewCart />} />
          <Route path="/confirm-address" element={<ConfirmAddress />} />
          <Route path="/successful-purchase" element={<SuccessfulPurchase />} />

          {/* user */}
          <Route path="/user" element={<Outlet />}>
            <Route index element={<User />} />
            <Route path="notifications" element={<User />} />
          </Route>
        </Route>
        {/* Login */}
        <Route path="/login" element={<RegistrationLayout />}>
          <Route index element={<Login />} />
          <Route path="forget-password" element={<ForgetPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="register" element={<Register />} />
          <Route path="register/address" element={<RegisterAddress />} />
          <Route path="register/check-email" element={<CheckEmail />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
