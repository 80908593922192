import Container from "../../components/general/container/Container";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const SuccessfulPurchase = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <div className="flex justify-center flex-col gap-[40px] mt-[120px] h-full items-center">
        <div className="text-[40px] font-black">تم الشراء بنجاح</div>
        <img src="/images/success.png" alt="" />

        <Button
          onClick={() => navigate("/")}
          className="w-[50%] mt-[40px] text-[20px] font-black py-[24px] h-auto"
          type="primary"
        >
          الرئيسية
        </Button>
      </div>
    </Container>
  );
};

export default SuccessfulPurchase;
