import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IProduct } from "../../models/product";
import http from "../../api/api";
import Container from "../../components/general/container/Container";
import { Breadcrumb } from "antd";
import ProductGrid from "../../components/general/productGrid.tsx/ProductGrid";

const SearchedProducts = () => {
  const { search } = useParams();
  const [searchedProducts, setSearchedProducts] = useState<IProduct[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const { data } = await http.get(`/products`, {
          params: {
            search,
          },
        });

        setSearchedProducts(data.data);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [search]);

  return (
    <Container>
      <Breadcrumb
        separator={<div className="text-[24px]">{`>`}</div>}
        items={[
          {
            title: (
              <Link className="text-[24px] p-[24px] hover:h-auto" to={"/"}>
                الرئيسية
              </Link>
            ),
          },
          {
            title: search,
            className: "text-[24px]",
          },
        ]}
      />
      <div className="mt-[70px]">
        <ProductGrid loading={loading} products={searchedProducts} />

        <div className="flex mt-[40px] justify-end">{/* <Pagination /> */}</div>
      </div>
    </Container>
  );
};

export default SearchedProducts;
