import React, { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import ProductCard from "../../../components/general/product-card/ProductCard";
import "./style.css";
import { IProduct } from "../../../models/product";

interface IProps {
  products: IProduct[];
}
const ProductsSlider = ({ products }: IProps) => {
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);
  return (
    <div
      className="products-slider flex gap-[70px] py-2 overflow-x-auto items-center"
      {...events}
      ref={ref}
    >
      {products.map((item: IProduct) => {
        return (
          <div className="w-full" key={item.id}>
            <ProductCard product={item} />
          </div>
        );
      })}
    </div>
  );
};

export default ProductsSlider;
