import { useContext, useEffect, useState } from "react";
import Container from "../../components/general/container/Container";
import { Breadcrumb, Button, Image, Spin, notification } from "antd";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { motion } from "framer-motion";
import BoxImages from "./box-images/BoxImages";
import ProductsSlider from "./products-slider/ProductsSlider";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IProduct } from "../../models/product";
import http from "../../api/api";
import { LoadingOutlined } from "@ant-design/icons";
import UserContext from "../../context.tsx/user/context";

interface IProductDetails {
  product: IProduct;
  suggestions: IProduct[];
}

const ProductDetails = () => {
  const [counter, setCounter] = useState<number>(1);

  const { product } = useParams();

  const [productDetails, setProductDetails] = useState<IProductDetails>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const getData = async () => {
      try {
        const { data } = await http.get(`/products/${product}`);
        setProductDetails(data.data);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };
    getData();

    return () => {
      setProductDetails(undefined);
      setLoading(true);
    };
  }, [product]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  const navigate = useNavigate();

  // context
  const { actions, isAuthenticated } = useContext(UserContext);

  return (
    <Container>
      <Breadcrumb
        separator={<div className="text-[24px]">{`>`}</div>}
        items={[
          {
            title: (
              <Link className="text-[24px] p-[24px] hover:h-auto" to={"/"}>
                الرئيسية
              </Link>
            ),
          },
          {
            title: "صفحة المنتج",
            className: "text-[24px]",
          },
        ]}
      />
      <div className="grid grid-cols-1 mt-[70px] lg:grid-cols-2 gap-[80px]">
        <div className="flex flex-col mt-[30px] gap-[30px]">
          <div className="flex flex-col gap-[20px]">
            <div className="text-black text-[32px] font-bold">
              {productDetails?.product.name}
            </div>
            <div className="flex flex-col gap-[10px]">
              <div className="text-[24px] ">
                {" "}
                $ {productDetails?.product.price}
              </div>
              {/* <div className="text-[16px] text-[#7A7A7A] ">
                $ {productDetails?.product.price}
              </div> */}

              {productDetails?.product?.offer_type && (
                <div className="text-[11px] text-[#B9B9B9]">
                  {productDetails?.product?.offer_type === "خصم بقيمة"
                    ? `${productDetails?.product?.offer} $`
                    : `${productDetails?.product?.offer} %`}
                </div>
              )}
            </div>
          </div>
          {/* Product Count */}
          <div className="flex justify-between items-center p-[20px] shadow-md w-[240px]">
            <motion.button
              whileTap={{ scale: 0.8 }}
              className="hover:bg-transparent bg-transparent   hover:text-[#F36B10] border-none outline-none p-0 m-0  h-[20px] w-[20px]"
              onClick={() => setCounter(counter + 1)}
            >
              <FaPlus className="hover:text-[#F36B10]  cursor-pointer tranistion ease-in-out" />
            </motion.button>
            <div className="text-[16px]">{counter}</div>
            <motion.button
              whileTap={{ scale: 0.8 }}
              className="hover:bg-transparent bg-transparent   hover:text-[#F36B10] border-none outline-none p-0 m-0  h-[20px] w-[20px]"
              onClick={() => {
                if (counter - 1 > 0) setCounter(counter - 1);
              }}
            >
              <FaMinus className="hover:text-[#F36B10] cursor-pointer tranistion ease-in-out" />
            </motion.button>
          </div>

          <div>
            <Button
              onClick={() => {
                if (!isAuthenticated) {
                  notification.info({
                    message: "يرجى تسجيل الدخول",
                    placement: "bottomRight",
                    style: { direction: "rtl" },
                  });
                  navigate("/login");
                } else {
                  if (productDetails) {
                    actions.addToCart(counter, productDetails.product);
                  }
                }
              }}
              className="rounded-none text-[24px] font-bold lg:w-[50%] xl:w-[40%] h-[60px]"
              type="primary"
            >
              أضف الى العربة
            </Button>
          </div>

          {/* Box Images */}
          <BoxImages
            images={productDetails?.product.cover ?? []}
            onClick={(index) => {
              setCurrentIndex(index);
            }}
            currentIndex={currentIndex}
          />
        </div>
        <div className="w-full flex justify-center px-[97px] py-[33px] items-center h-full bg-[#F8F8F8]">
          {loading ? (
            <Spin indicator={antIcon} />
          ) : (
            <Image
              preview={false}
              src={productDetails?.product.cover[currentIndex]}
              alt="product details"
            />
          )}
        </div>
      </div>

      <div className="mt-[20px] flex flex-col gap-[20px]">
        <div className="text-[24px]">الوصف</div>
        <div
          dangerouslySetInnerHTML={{
            __html: productDetails?.product.description ?? "",
          }}
          style={{ lineHeight: "30px" }}
          className="text-[16px] "
        ></div>
      </div>

      <div className="flex flex-col gap-[40px] mt-[40px]">
        <div className="text-[30px] font-bold">منتجات ذات صلة</div>
        <ProductsSlider products={productDetails?.suggestions ?? []} />
      </div>
    </Container>
  );
};

export default ProductDetails;
