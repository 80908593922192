import { createContext } from "react";
import { IHomeData } from "../../models/home";

export interface IInternalState {
  loading: boolean;
  data?: IHomeData;
}

export const internalState: IInternalState = {
  loading: true,
  data: undefined,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: () => null,
  },
};

const HomeContext = createContext(externalState);

export default HomeContext;
