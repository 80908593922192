import { Avatar, Badge, Button, Drawer, Input } from "antd"
import { useContext, useState } from "react"
import { FaRegBell, FaSearch, FaSignOutAlt } from "react-icons/fa"
import { FaBars } from "react-icons/fa6"
import { useNavigate } from "react-router"
import UserContext from "../../../context.tsx/user/context"

const Navbar = () => {
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const { isAuthenticated, user, actions, cart, notifications } =
    useContext(UserContext)

  const [search, setSearch] = useState("")

  return (
    <div
      style={{ height: "110px" }}
      className=" w-full px-[20px] md:px-[60px] lg:px-[140px] z-10 shadow-lg gap-[50px] justify-between flex items-center"
    >
      <img
        onClick={() => navigate("/")}
        className="cursor-pointer w-[200px]"
        src="/images/logo.png"
        alt="logo"
      />

      {/* mobile */}

      <Button
        type="default"
        onClick={showDrawer}
        className="md:hidden text-[24px] hover:cursor-pointer shadow-none outline-none border-none hover:text-[#F36B10]"
      >
        <FaBars />
      </Button>
      <Drawer
        title="القائمة الجانبية"
        placement="left"
        width={300}
        onClose={onClose}
        open={open}
      >
        <div className="flex flex-col gap-[20px]">
          {/* <Input
            value={search}
            prefix={<FaSearch color="#52555A" />}
            className="rounded-none"
          /> */}

          <form
            onSubmit={(e) => {
              e.preventDefault()
              navigate(`/search-products/${search}`)
            }}
            className="w-full "
          >
            <Input
              prefix={<FaSearch color="#52555A" />}
              className={`rounded-none  w-full md:inline-flex `}
              value={search}
              onChange={(val) => {
                setSearch(val.target.value)
              }}
            />
          </form>

          {!isAuthenticated ? (
            <>
              <Badge
                className="bg-white cursor-pointer self-center"
                style={{ direction: "rtl" }}
                showZero
                offset={[22, 8]}
                styles={{
                  indicator: {
                    backgroundColor: "white",
                    color: "#F36B10",
                    border: "2px solid #F36B10",
                    fontWeight: "bold",
                    fontSize: "8px",
                  },
                  root: { alignContent: "center", padding: "4px" },
                }}
                size="small"
                count={notifications?.length ?? 0}
                color="red"
              >
                <FaRegBell
                  onClick={() => {
                    if (!isAuthenticated) {
                      navigate("/login")
                    } else {
                      navigate("/user/notifications")
                    }
                  }}
                  color="grey"
                  className="w-[21px] h-[21px]"
                />
              </Badge>

              <div
                onClick={() => navigate("view-cart")}
                className="text-[16px] self-center shrink-1 flex font-bold cursor-pointer hover:text-[#F36B10] transition ease-in-out"
              >{`السلة(${cart.length})`}</div>
              <div
                style={{ inlineSize: "94px" }}
                className="text-[16px] self-center shrink inline-block font-bold hover:text-[#F36B10] transition ease-in-out cursor-pointer"
                onClick={() => navigate("/login")}
              >
                تسجيل الدخول
              </div>
            </>
          ) : (
            <div className="flex flex-col gap-[20px]  items-center">
              <Badge
                className="bg-white cursor-pointer"
                style={{ direction: "rtl" }}
                showZero
                offset={[22, 8]}
                styles={{
                  indicator: {
                    backgroundColor: "white",
                    color: "#F36B10",
                    border: "2px solid #F36B10",
                    fontWeight: "bold",
                    fontSize: "8px",
                  },
                  root: { alignContent: "center", padding: "4px" },
                }}
                size="small"
                count={notifications?.length ?? 0}
                color="red"
              >
                <FaRegBell
                  onClick={() => {
                    navigate("/user/notifications")
                  }}
                  color="grey"
                  className="w-[21px] h-[21px]"
                />
              </Badge>
              <div
                onClick={() => navigate("view-cart")}
                className="text-[16px] shrink-1 flex font-bold cursor-pointer hover:text-[#F36B10] transition ease-in-out"
              >{`السلة(${cart.length})`}</div>

              <div
                onClick={() => navigate("/user")}
                className="text-[12px] hover:text-[#F36B10] whitespace-nowrap cursor-pointer font-bold"
              >
                {user?.name}
              </div>

              <Avatar
                onClick={() => navigate("/user")}
                className="cursor-pointer bg-gray-500"
                src={user?.photo}
              />

              <FaSignOutAlt
                fontSize={24}
                onClick={() => actions.logout()}
                fontWeight={200}
                className="hover:text-[#F36B10] cursor-pointer"
              />
            </div>
          )}
        </div>
      </Drawer>

      {/* mid and above */}

      <form
        onSubmit={(e) => {
          e.preventDefault()
          navigate(`/search-products/${search}`)
        }}
        className="w-full hidden md:inline-flex"
      >
        <Input
          prefix={<FaSearch color="#52555A" />}
          className={`rounded-none ${
            isAuthenticated ? "w-full" : ""
          } hidden md:inline-flex `}
          value={search}
          onChange={(val) => {
            setSearch(val.target.value)
          }}
        />
      </form>

      <div className="hidden md:flex gap-[40px] items-center ">
        {!isAuthenticated ? (
          <>
            <Badge
              className="bg-white cursor-pointer"
              style={{ direction: "rtl" }}
              showZero
              offset={[22, 8]}
              styles={{
                indicator: {
                  backgroundColor: "white",
                  color: "#F36B10",
                  border: "2px solid #F36B10",
                  fontWeight: "bold",
                  fontSize: "8px",
                },
                root: { alignContent: "center", padding: "4px" },
              }}
              size="small"
              count={0}
              color="red"
            >
              <FaRegBell
                onClick={() => {
                  if (!isAuthenticated) {
                    navigate("/login")
                  } else {
                    navigate("/user/notifications")
                  }
                }}
                color="grey"
                className="w-[21px] h-[21px]"
              />
            </Badge>
            <div
              onClick={() => {
                if (!isAuthenticated) {
                  navigate("/login")
                } else {
                  navigate("view-cart")
                }
              }}
              className="text-[16px] shrink-1 flex font-bold cursor-pointer hover:text-[#F36B10] transition ease-in-out"
            >{`السلة(${cart.length})`}</div>

            <div
              style={{ inlineSize: "94px" }}
              className="text-[16px] shrink inline-block font-bold hover:text-[#F36B10] transition ease-in-out cursor-pointer"
              onClick={() => navigate("/login")}
            >
              تسجيل الدخول
            </div>
          </>
        ) : (
          <>
            <Badge
              className="bg-white cursor-pointer"
              style={{ direction: "rtl" }}
              showZero
              offset={[22, 8]}
              styles={{
                indicator: {
                  backgroundColor: "white",
                  color: "#F36B10",
                  border: "2px solid #F36B10",
                  fontWeight: "bold",
                  fontSize: "8px",
                },
                root: { alignContent: "center", padding: "4px" },
              }}
              size="small"
              count={notifications?.length ?? 0}
              color="red"
            >
              <FaRegBell
                onClick={() => {
                  navigate("/user/notifications")
                }}
                color="grey"
                className="w-[21px] h-[21px]"
              />
            </Badge>
            <div
              onClick={() => navigate("view-cart")}
              className="text-[16px] shrink-1 flex font-bold cursor-pointer hover:text-[#F36B10] transition ease-in-out"
            >{`السلة(${cart.length})`}</div>

            <div
              onClick={() => navigate("/user")}
              className="text-[12px] hover:text-[#F36B10] whitespace-nowrap cursor-pointer font-bold"
            >
              {user?.name}
            </div>

            <Avatar
              onClick={() => navigate("/user")}
              className="cursor-pointer bg-gray-500"
              src={user?.photo}
            />

            <FaSignOutAlt
              fontSize={24}
              onClick={() => actions.logout()}
              fontWeight={200}
              className="hover:text-[#F36B10] cursor-pointer"
            />
          </>
        )}
      </div>
    </div>
  )
}

export default Navbar
