import { useContext } from "react";
import ProductGrid from "../../productGrid.tsx/ProductGrid";
import { Link } from "react-router-dom";
import HomeContext from "../../../../context.tsx/home/context";

const LatestOfferSection = () => {
  const { loading, data } = useContext(HomeContext);
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="text-[30px] font-bold">أحدث العروض</div>
        <Link
          to={"/latest-offers"}
          className="text-black hover:underline hover:text-[#F36B10] transition ease-in-out text-[24px] "
          // style={{ color: "black" }}
          color="black"
        >
          عرض الكل
        </Link>
      </div>

      <ProductGrid
        loading={loading}
        products={data?.offers.map((offer) => offer.product) ?? []}
      />
    </>
  );
};

export default LatestOfferSection;
