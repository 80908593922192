import { useEffect, useReducer } from "react";
import HomeContext, { internalState } from "./context";
import reducer from "./reducer";
import http from "../../api/api";

export interface IProps {
  children: React.ReactNode;
}

const HomeContextProvider = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async () => {
    try {
      const { data } = await http.get("/home");
      dispatch({ type: "SET_DATA", payload: { data: data.data } });
    } catch (err) {
    } finally {
      dispatch({ type: "LOADING", payload: { loading: false } });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <HomeContext.Provider
      value={{
        ...state,
        actions: {
          getData,
        },
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export default HomeContextProvider;
