import React, { Fragment, useRef } from "react";
import Category from "../category/Category";
import { useDraggable } from "react-use-draggable-scroll";
import { motion } from "framer-motion";
import "./style.css";
import { LoadingOutlined } from "@ant-design/icons";
import { ICategory } from "../../../models/category";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
interface Props {
  navigateUrl: string;
  categories: ICategory[];
  loading: boolean;
}
const CategoryContainer = ({ navigateUrl, categories, loading }: Props) => {
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);
  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  const navigate = useNavigate();

  return (
    <>
      {loading && (
        <div className="flex w-full justify-center mt-[20px]">
          <Spin indicator={antIcon} />{" "}
        </div>
      )}
      <div style={{ display: loading ? "none" : "" }} className="md:pr-[140px]">
        <motion.div
          className="category-container flex gap-[40px] overflow-x-auto items-center  h-[300px] mt-[20px]"
          {...events}
          ref={ref}
        >
          {categories?.map((item) => (
            <Fragment key={item.id}>
              <Category
                navigteUrl={() =>
                  navigate(`${navigateUrl}/${item.id}`, {
                    state: { name: item.name },
                  })
                }
                name={item.name}
                url={item.cover}
              />
            </Fragment>
          ))}
        </motion.div>
      </div>
    </>
  );
};

export default CategoryContainer;
