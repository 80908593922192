import { Button, Card, notification } from "antd";
import { FaHeart } from "react-icons/fa";
import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
import { IProduct } from "../../../models/product";
import { useContext } from "react";
import UserContext from "../../../context.tsx/user/context";

interface IProps {
  product: IProduct;
}
const ProductCard = ({ product }: IProps) => {
  const navigate = useNavigate();

  const { actions, isAuthenticated, userFavourites } = useContext(UserContext);

  // 👇️ check if array contains object
  const isFound = userFavourites.some((element) => {
    if (element.id === product.id) {
      return true;
    }

    return false;
  });

  return (
    <Card
      className={styles.productCard}
      hoverable
      cover={
        <div className="w-full h-full bg-[#F9F9F9] flex justify-center items-center relative">
          <div className="w-full h-full flex justify-center">
            <img
              onClick={() => navigate(`/product/${product.id}`)}
              className="pl-[16px] cursor-pointer pr-[16px] w-[200px] object-contain h-[200px] pt-[24px] pm-[24px]"
              alt="example"
              src={product.cover[0]}
            />
          </div>
          <div className="absolute top-0 pr-[15px] pt-[15px]">
            <div className={!isFound ? styles.showActions : ""}>
              <FaHeart
                onClick={() => {
                  if (!isAuthenticated) {
                    navigate("/");
                  } else {
                    actions.updateUserFavourites(product);
                  }
                }}
                className={`w-[23px] h-[20px] text-[#D2D2D2]  transition duration-200 cursor-pointer ${
                  isFound
                    ? "text-[#F36B10] hover:text-[#F36105]"
                    : "hover:text-[#F36B10]"
                }`}
              />
            </div>
          </div>
          <div className="botom-0 pb-[15px] pr-[15px]">
            <div className={!isFound ? styles.showActions : ""}>
              <Button
                onClick={() => {
                  if (!isAuthenticated) {
                    notification.info({
                      message: "يرجى تسجيل الدخول",
                      placement: "bottomRight",
                      style: { direction: "rtl" },
                    });
                    navigate("/login");
                  } else {
                    actions.addToCart(1, product);
                  }
                }}
                type="primary"
                className="rounded-none"
                color="#F36B10"
              >
                اضف الى السلة
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <div className="mt-[15px]">
        <div className="flex flex-col gap-[15px]">
          <div
            onClick={() => navigate(`/product/${product.id}`)}
            className="text-[24px] font-bold hover:text-[#F36B10] cursor-pointer transition ease-in-out"
          >
            {product.name}
          </div>

          <div className="flex flex-col gap-[7.5px]">
            <div className="text-[18px]">{product.price} $</div>
            {product.offer_type && (
              <div className="text-[11px] text-[#B9B9B9]">
                {product?.offer_type === "خصم بقيمة"
                  ? `${product?.offer} $`
                  : `${product.offer} %`}
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ProductCard;
